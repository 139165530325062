import React from "react"
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'
import Banner from "../../components/compare-banner"
import List from "../../components/compare-list"
import Testimonial from '../../components/testimonial'
import ComparisonTable from "../../components/comparison-table"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import SwiperTabs from '../../components/swiper-tabs'
import { swiperTabsContent } from "../../lib/swiper-tabs-content"
// import { testimonialContent } from "../../lib/testimonial-content"

const bannerContent = {
  title: "Looking for a Subbly alternative? You're in the right place.",
  text: "See why every day businesses choose Billsby - the most powerful, customizable and easy-to-integrate recurring billing software and compare Subbly to the platform that says if you can dream it, we can bill it.",
  compLogo: require('../../images/subbly.svg'),
  billsbyImage: require('../../images/compare-banner@2x.png'),
  compareImage: require('../../images/compare-banner2.svg'),
  svg: true,
}

const listContent = {
  bgColor: '',
  content: [
    {
      title: "Swap chaos and complexity for clarity and structure.",
      image: require('../../images/comparison-blocks.svg'),
      svg: true,
      list: [
        {
          subtitle: "Integrations and advanced features as standard",
          text: " Subbly is great when you're starting out, but when you need integrations and advanced features you need a platform that was designed with developers first"
        },
        {
          subtitle: "Feature tags",
          text: "Need to figure out access control? Don't do it manually - use feature tags to make it easy to give your subscribers the correct permissions."
        },
        {
          subtitle: "Easy to get started ",
          text: "You don't need a developer to understand Billsby - but with this Subbly alternative, you'll also set your business up for growth."
        },
      ]
    },
    {
      title: "Pick great APIs that reduce your workload and speed up integration.",
      image: require('../../images/integration.svg'),
      svg: true,
      list: [
        {
          subtitle: "Real-time usage tracking ",
          text: "Give us real time usage information and we can bill your customers for how much they use your product."
        },
        {
          subtitle: "Drop-in checkout and account management",
          text: "Billsby's checkout and account management tools include custom fields - so you can do whatever data collection you need without third party tools."
        },
        {
          subtitle: "If you can do it in Billsby, you can do it with the API",
          text: "We designed Billsby so that everything you can do on the control panel can be done in our advanced API - so there are no limits to your creativity."
        },
      ]
    },
    {
      title: "Pricing that scales as your business grows - in the right direction.",
      image: require('../../images/pricing.svg'),
      svg: true,
      list: [
        {
          subtitle: "Start with a one month free trial",
          text: "Every Billsby customer starts with a one month free trial - so you can see if we're the right subbly alternative for you."
        },
        {
          subtitle: "Low revenue share and no setup fees",
          text: "Compare Subbly, who charge revenue share as high as 1.25% with our low monthly cost and transparent 0.4% overage fee."
        },
        {
          subtitle: "Advanced feature access",
          text: "Get immediate access to advanced features without having to upgrade to a more expensive plan"
        },
      ]
    },
  ]
}

const listConten2 = {
  bgColor: "blue",
  content: [
    {
      title: "Get started quickly and easily, and get help when you need it.",
      image: require('../../images/comparison-doors.svg'),
      svg: true,
      list: [
        {
          subtitle: "Obsessive support",
          text: "From in-app chat to phone calls on your schedule, all the support you need is included with every Billsby plan - great service should never be an upsell."
        },
        {
          subtitle: "No complex contracts",
          text: "Unlike Subbly, we don't hide our most advanced features behind expensive Enterprise plans. Our pricing is open, honest and clear."
        },
        {
          subtitle: "Integration support ",
          text: "Our development services team can help build out your end-to-end subscription business with custom integrations for just $25 per hour."
        },
      ]
    },
    {
      title: "Go beyond subscription management",
      bgColor: "blue",
      btnName: "COMING SOON",
      image: require('../../images/management.svg'),
      svg: true,
      list: [
        {
          subtitle: "Value score",
          text: "Truly understand your customers - get to know who your best and worst customers are and customise your experience to suit their needs."
        },
        {
          subtitle: "Plan actions",
          text: "Integrate your subscription journey with the other tools and services your business uses to support your customers effectively."
        },
        {
          subtitle: "Customer profile reporting",
          text: "Enrich your subscriber records with extra data - like how much they earn, where they live or what they do for a living."
        },
      ]
    },
  ]
}

const comparisonTableContent = {
  title: 'Bigger, better features',
  description: "Feature-by-feature, you get more options, power and customization when you choose Billsby - and there's no need to upgrade to more expensive plans to take advantage of them either.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: require('../../images/subbly.svg'),
      svgLogo: true,
      title: 'Subbly' 
    },
  ],
  body: [
    {
      title: 'Create unlimited products, plans and cycles',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Connect unlimited payment gateways',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Have unlimited adminstrators',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Free trials, setup fees, minimum terms and pro-rating',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Add-ons and allowances with real time usage API',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: 'Sell globally with unlimited currencies and tax profiles',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Advanced cancellation and retention tools',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
      ]
    },
    {
      title: 'Plan actions and value score',
      subtitle: 'COMING SOON',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up today',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const comparisonTableContent2 = {
  title: 'Fairer, clearer pricing',
  description: "At Billsby, we don't make money when you're not making money. Our simple 0.8% revenue share is all you'll pay to use Billsby to power your subscription business with all the features you need to get started and grow.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: require('../../images/subbly.svg'),
      title: 'Subbly',
      svgLogo: true,
    },
  ],
  body: [
    {
      title: "free trial",
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faTimesCircle }  className="icon-cross"/> 
        },
      ]
    },
    {
      title: "If you make $25,000 per month, you'll pay",
      content: [
        {
          itemContent: "$200" 
        },
        {
          itemContent: "$269" 
        },
      ]
    },
    {
      title: "If you make $50,000 per month, you'll pay",
      content: [
        {
          itemContent: "$400"
        },
        {
          itemContent: "$519"
        },
      ]
    },
    {
      title: "If you make $100,000 per month, you'll pay",
      content: [
        {
          itemContent: "$800"
        },
        {
          itemContent: "$1019"
        },
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up today',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const Subbly = () => (
  <Layout isLandingPage>
    <SEO 
      title="Subbly Alternative | Billsby" 
      description="If you're looking for a Subbly alterative to manage subscription billing and recurring payments, compare Subbly to Billsby." 
      url="https://www.billsby.com/compare/compare-subbly"
    />

    <div className="compare-page subbly">
      <Banner content={bannerContent}/>
      <List compare={listContent}/>
      <Testimonial />
      <ComparisonTable content={comparisonTableContent}/>
      {/* <ComparisonTable content={comparisonTableContent2}/> */}
      <List compare={listConten2}/>
      <SwiperTabs 
        tabs={swiperTabsContent} 
        title="The bottom line? If you can dream it, we can bill it."
        description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans."
      />
    </div>
  </Layout>
)

export default Subbly



